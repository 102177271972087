<template>
  <div class="new-link">
    <h1>{{labels.expiredHeading}}</h1>
    <h4>{{labels.expiredText}}</h4>
    <mcw-textfield
        v-model.trim="email"
        :label="labels.email"
        :helptext="labels.emailDescrtiption"
        helptext-persistance
        type="email"
        required
        outline
        secondary
    ></mcw-textfield>
    <mcw-button
      @click.prevent="sendNewLinkRequest"
      raised>
      {{labels.expiredButton}}
    </mcw-button>
    <mcw-snackbar
      v-model="success"
      :message="labels.newLinkSuccess"
      :dismissAction="false"
    ></mcw-snackbar>
    <mcw-snackbar
      v-model="error"
      :message="labels.newLinkError"
      :dismissAction="false"
    ></mcw-snackbar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      labels: {
        expiredHeading: 'Срок действия временной ссылки истек. Для получения новой ссылки нажмите кнопку ниже',
        expiredText: 'Для получения новой ссылки на электронную почту, нажмите на кнопку',
        expiredButton: 'Получить новую ссылку',
        email: 'E-mail',
        emailDescrtiption: 'Адрес электронной почты, куда отправить ссылку на восстановление пароля',
        newLinkSuccess: 'Новая ссылка отправлена вам на почту',
        newLinkError: 'Что-то пошло не так :-( Обратитесь в техподдержку',
      },
      email: '',
      success: false,
      error: false,
    };
  },
  methods: {
    async sendNewLinkRequest() {
      const result = await this.$store.dispatch('auth/newLinkRequest', this.email);
      if (!result.err) {
        this.success = true;
      } else {
        this.error = true;
      }
    },
  },
};
</script>

<style>

</style>
